<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';


export default {
    extends: GlobalVue,
    data() {
        return {
            menu:{},
            menuInfo:{},
            ppdbactive: false,
        }
    },
    mounted() {
        this.getPpdb();
        setTimeout(() => {
          this.addCurrent();
        }, 50);
    },
    watch: {
      '$route.path'() {
        this.getPpdb();
        setTimeout(() => {
          this.addCurrent();
        }, 50)
      },
  
    },
    methods: {
      getPpdb() {
        Gen.apirest("/header", {}, (err, resp) => {
          if (err) console.log("Header " + err);
          this.ppdbactive = resp.result;
        });
      },
      addCurrent() {
        $('.menu-item').removeClass('current');
        const menu = $('.router-link-active');
        if(menu.parent().parent().hasClass('sub-menu-container')) {
          menu.parent().addClass('current');
          menu.parent().parent().parent().addClass('current');
        }
        menu.parent().addClass('current');
      }
    },
};
</script>

<template>
    <header itemscope itemtype="http://schema.org/WPHeader" id="header" data-sticky-class="not-dark" data-sticky-shrink="false" data-logo-height="80" data-sticky-height="80" data-menu-padding="29" data-sticky-menu-padding="29" class="transparent-header">
  <div id="header-wrap">
    <div class="container">
      <div class="header-row">

        <!-- Logo
                        ============================================= -->
        <div id="logo" itemscope itemtype="https://schema.org/School">
          <h1>
          <span class="d-none">SDI Al Hidayah Pamulang</span>
            <router-link :to="{name:'Index'}" class="standard-logo" :data-dark-logo="assets('fo_images','logo.png')">
              <img :src="assets('fo_images','logo.png')" alt="Logo SDI Al Hidayah" itemprop="logo">
            </router-link>
            <router-link :to="{name:'Index'}" class="retina-logo" :data-dark-logo="assets('fo_images','logo@2x.png')">
              <img :src="assets('fo_images','logo@2x.png')" alt="Logo SDI Al Hidayah" itemprop="logo">
            </router-link>
          </h1>
        </div><!-- #logo end -->

        <div id="primary-menu-trigger">
          <svg class="svg-trigger" viewBox="0 0 100 100">
            <path
              d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20">
            </path>
            <path d="m 30,50 h 40"></path>
            <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20">
            </path>
          </svg>
        </div>

        <!-- Primary Navigation
                        ============================================= -->
        <nav itemscope itemtype="https://schema.org/SiteNavigationElement" class="primary-menu with-arrows">

          <ul class="menu-container">
            <li class="menu-item">
              <a class="menu-link" href="#">
                <div>Tentang</div>
              </a>
              <ul class="sub-menu-container">
                <li class="menu-item">
                  <router-link :to="{name:'About'}" class="menu-link"><div>SDI Al Hidayah</div></router-link>
                </li>
                <li class="menu-item">
                  <router-link :to="{name:'AboutFoundation'}" class="menu-link"><div>Yayasan Masjid Al Hidayah Pamulang Permai I</div></router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item">
              <router-link :to="{name:'NewsArticle'}" class="menu-link"><div>Berita dan Artikel</div></router-link>
            </li>
            <li class="menu-item">
              <a class="menu-link" href="#">
                <div>Akademik</div>
              </a>
              <ul class="sub-menu-container">
                <li class="menu-item">
                  <router-link :to="{name:'Academic'}" class="menu-link"><div>Kalender Akademik</div></router-link>
                </li>
                <li class="menu-item">
                  <router-link :to="{name:'Curriculum'}" class="menu-link"><div>Kurikulum</div></router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item">
              <router-link :to="{name:'Gallery'}" class="menu-link"><div>Galeri</div></router-link>
            </li>
            <li class="menu-item d-lg-none d-xl-block">
              <router-link :to="{name:'Contact'}" class="menu-link"><div>Hubungi Kami</div></router-link>
            </li>
            <li v-if="ppdbactive" class="menu-item menu__cta d-lg-none d-xl-block">
              <router-link :to="{name:'PPDB'}" class="cta cta--green header-cta">PPDB Online</router-link>
            </li>
            <li class="menu-item d-none d-lg-block d-xl-none">
              <a href="#" class="menu-link"><div>Lainnya</div></a>
              <ul class="sub-menu-container">
                <li class="menu-item"><router-link :to="{name:'Contact'}" class="menu-link"><div>Hubungi Kami</div></router-link></li>
                <li class="menu-item"><router-link :to="{name:'PPDB'}" class="menu-link"><div>PPDB Online</div></router-link></li>
              </ul>
            </li>
          </ul>

        </nav>

      </div>
    </div>
  </div>
  <div class="header-wrap-clone"></div>
</header>
</template>
<style scoped>
  h1 {
    margin: 0;
    padding: 0;
  }
</style>